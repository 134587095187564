import React from "react";
import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";
import ListCard from "components/Cards/ListCard";

const SchoolPrayerView = (props) => {
  const { t } = useTranslation();
  const { isSm, campaigns, eRosary, proyects, handleClickSchoolPrayer } = props;

  const classes = useStyles({
    mediaQuerySm: isSm,
  });

  return (
    <div data-testid={`school_prayer_view`} className={classes.mainContent}>
      <section className={`${classes.sectionCard}`}>
        <div className={classes.wrapperMain}>
          <div className={classes.sectionTitle}>
            <h1 data-testid="txt_title_school_prayer">
              {t(`pages.schoolPrayer.School of Prayer`)}
            </h1>
            {isSm && <hr className={classes.lineH} />}
            <h6 data-testid="txt_challenges_humanity">
              {t(
                `pages.schoolPrayer.In order to pray deeply and grow in availability to the mission of Christ`
              )}
            </h6>
          </div>
          <div className={classes.contentBody}>
            {!!campaigns.length && (
              <div className={classes.contentBodySection}>
                <h2
                  data-testid="txt_praying_campaigns"
                  className={classes.sectionTitle}
                >
                  {t(`pages.schoolPrayer.Prayer proposals`)}
                </h2>
                <div className={classes.wrapperListCard}>
                  <ListCard
                    handleClickPrayCard={handleClickSchoolPrayer}
                    doubleCard
                    list={campaigns}
                    isSmall={isSm}
                  />
                </div>
              </div>
            )}
            {!!proyects.length && (
              <div className={classes.contentBodySection}>
                <h2
                  data-testid="txt_learn_to_pray"
                  className={classes.sectionTitle}
                >
                  {t(`pages.schoolPrayer.Deepen your prayer`)}
                </h2>
                <div className={classes.wrapperListCard}>
                  <ListCard
                    handleClickPrayCard={handleClickSchoolPrayer}
                    doubleCard
                    list={proyects}
                    isSmall={isSm}
                  />
                </div>
              </div>
            )}
            {!!eRosary.length && (
              <div className={classes.contentBodySection}>
                <h2
                  data-testid="txt_eRosaries"
                  className={classes.sectionTitle}
                >
                  {t(`pages.schoolPrayer.eRosaries`)}
                </h2>
                <div className={classes.wrapperListCard}>
                  <ListCard
                    handleClickPrayCard={handleClickSchoolPrayer}
                    doubleCard
                    list={eRosary}
                    isSmall={isSm}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default SchoolPrayerView;
