/* eslint-disable */
import axios from "axios";
import { API_URL } from "../../conf/env";
import i18n from "i18n";
import { refreshToken } from "services/services";
import {
  clearSession,
  getAccessToken,
  getRefreshToken,
  setRefreshToken,
} from "services/authService";
import { toHome } from "helpers";
const http = axios.create({
  baseURL: 'https://api-dev.clicktopray.org',//API_URL,
});

let isRefreshing = false;

http.interceptors.request.use(
  (config) => {
    //Only add access-token if url isn't for refresh it
    if (config.url.indexOf("auth/refresh-token") === -1 && !!getAccessToken())
      config.headers["access-token"] = "Bearer " + getAccessToken();
    config.headers["Accept-Language"] = i18n.language;
    return config;
  },
  (error) => Promise.reject(error)
);

http.interceptors.response.use(
  (res) => res.data,
  async (error) => {
    const expectedError =
      error.response &&
      error.response.status >= 400 &&
      error.response.status <= 500;

    if (expectedError) {
      const originalConfig = error.config;

      if (error.response.status === 401 && !originalConfig._retry) {
        if (!/(sign-in)/gi.test(originalConfig.url) && error.response) {
          originalConfig._retry = true;
          try {
            // If some request is refreshing the token, put the retry request inside an interval to wait it's finished.
            if (!isRefreshing) {
              isRefreshing = true;
              originalConfig.headers["access-token"] = undefined;
              const refresh = await refreshToken(getRefreshToken());
              setRefreshToken(refresh?.access_token, refresh?.expiration, refresh?.refresh_token);
              isRefreshing = false;
              return http(originalConfig);
            } else {
              return new Promise((resolve, reject) => {
                const idInterval = setInterval(() => {
                  // if the state indicates that there is no refresh token request anymore, it clears the time interval and retries the failed API call with updated token data
                  if (!isRefreshing) {
                    clearInterval(idInterval);
                    isRefreshing = false;
                    resolve(http(originalConfig));
                  }
                }, 1000);
              });
            }
          } catch (_error) {
            // It couldn't refresh. Should send it to login page?
            clearSession(toHome);
            return Promise.reject(_error);
          }
        }
      }
      return Promise.reject(error.response.data);
    }
  }
);
export default http;
