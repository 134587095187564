import React from "react";
import { useStyles } from "./styles";
import Button from "components/Button";
import { useTranslation } from "react-i18next";
import { Avatar, Grid } from "@material-ui/core";
import PopeAvatar from "assets/icons/Pope_photo_large.svg";
import SinglePrayer from "components/SinglePrayer";
import {deleteCommunityComment} from "../../services/services";

const PopeProfileView = (props) => {
  const {
    isSm,
    prayers,
    hasNext,
    onChangeExpandedPost,
    handleFavorite,
    handleDislike,
    handlePostComments,
    handleEditComments,
    handleReportComment,
    handleUpdateComments
  } = props;
  const { t } = useTranslation();
  const classes = useStyles({ mediaQuerySm: isSm });
  return (
    <div data-testid={`popeProfile_view`}>
      <section className={classes.wrapperSection}>
        <Grid container>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <div className={classes.avatarWrapper}>
              <Avatar
                data-testid="img_avatar"
                id="img_avatar"
                src={PopeAvatar}
                className={classes.avatar}
              />
            </div>
            <div>
              <p data-testid="txt_pope_francis" className={classes.title}>
                {t("pages.popeProfile.Pope Francis")}
              </p>
              <p data-testid="txt_pope_subtitle" className={classes.subtitle}>
                {t("pages.popeProfile.Pope Francis' official prayer profile")}
              </p>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            className={classes.list}
          >
            {prayers?.map((prayer, index) => (
              <SinglePrayer
                key={index}
                description={prayer?.description}
                title={prayer?.title}
                isPlay={false}
                isSmall={isSm}
                isPrayer={true}
                created_at={prayer?.created_at}
                isPopePrayer={true}
                postImage={prayer?.multimedia?.find(m=>m.type?.code === 'IMG')}
                video={prayer?.video_url}
                css={classes.contentSinglePlayer}
                isTotalDescription={true}
                index={index}
                handleFavorite={() => {
                  handleFavorite(prayer?.id, prayer.ownerType, index);
                }}
                handleDislike={() => {
                  handleDislike(prayer?.id, prayer.ownerType, index);
                }}
                owner={prayer?.id}
                ownerGroup={prayer?.groupData}
                ownerType={prayer.ownerType}
                handleMoreComments={()=>{}}
                handleReportPost={()=>{}}
                totalComments={prayer?.comments?.length||0}
                comments={prayer?.comments?.slice(0,2)}
                handlePostComments={(values) => handlePostComments(values, prayer, index)}
                handleEditComments={handleEditComments(prayer, index)}
                handleReportComment={handleReportComment(prayer, index)}
                handleDeleteComment={(id) => {
                  deleteCommunityComment(id).then(() => {
                    handleUpdateComments(prayer, index)
                  });
                }}
              />
            ))}
            {hasNext && (
              <div className={classes.contentButtonMore}>
                <Button
                  type="cuaternary"
                  text="pages.popeProfile.Load More Prayers"
                  data-testid={`btn_expanded_post`}
                  onClick={() => onChangeExpandedPost()}
                />
              </div>
            )}
          </Grid>
        </Grid>
      </section>
    </div>
  );
};

export default PopeProfileView;
