export const branchOffices = [
  // Africa
  {
    code: "PWPN/EYM",
    name: "ANGOLA",
    offices: [
      {
        code: "PWPN",
        name: "Fr. Valter João Cassamano SJ",
        role: "National Coordinator",
        email: "gualbertocassamano@gmail.com",
      },
      {
        code: "EYM",
        name: "Félix Nambi",
        role: "National Coordinator",
        email: "felixnambi20@gmail.com",
      },

    ],
  },
  {
    code: "MEJ",
    name: "BENIN",
    offices: [
      {
        code: "MEJ",
        name: "Flor Bianco ODN",
        role: "Contact",
        email: "florbiancoecj@gmail.com",
      },
    ],
  },
  {
    code: "PWPN",
    name: "BURKINA FASO",
    offices: [
      {
        code: "PWPN",
        name: "Abbé Silvère Yameogo",
        role: "National Director",
        email: "silvereyameogo@gmail.com",
      },
    ],
  },
  {
    code: "RMPP/MEJ",
    name: "BURUNDI",
    offices: [
      {
        code: "RMPP",
        name: "P. Kalisa Gérard SJ",
        role: "Regional Coordinator",
        email: "gkalisa@yahoo.fr",
      },
      {
        code: "MEJ",
        name: "Abbé Remesha, Désiré Sept",
        role: "National Coordinator",
        email: "remd7@yahoo.fr",
      },
    ],
  },
  {
    code: "RMPP/RMPP(MEJ)/MEJ",
    name: "CAMEROON",
    offices: [
      {
        code: "RMPP",
        name: "M. Eric Oteté",
        role: "National Coordinator",
        email: "rmpp237@gmail.com",
      },
      {
        code: "RMPP(MEJ)",
        name: "P. Hugues Christian Bokouamanga SJ",
        role: "National Chaplain",
        email: "hugueschristianbokouamanga@jesuits.net",
      },
      {
        code: "MEJ",
        name: "Flora Kouetche",
        role: "Responsable Nationale MEJ",
        email: "kouetcheflora@gmail.com",
      },
    ],
  },
  {
    code: "MEJ",
    name: "CONGO BRAZZAVILLE",
    offices: [
      {
        code: "MEJ",
        name: "Japhet Matingou",
        role: "Group Leader",
        email: "mej.congobrazza@gmail.com",
      },
    ],
  },
  {
    code: "PWPN/EYM",
    name: "ETHIOPIA",
    offices: [
      {
        code: "PWPN",
        name: "Biruktawit Kassa",
        role: "National Coordinator",
        email: "biruktawitkassa@gmail.com",
      },
      {
        code: "EYM",
        name: "Bililign Bantayehu Shewaye",
        role: "National Coordinator",
        email: "",
      },
    ],
  },
  {
    code: "MEJ",
    name: "GABON",
    offices: [
      {
        code: "MEJ",
        name: "Jean Michel Megne",
        role: "National Coordinator",
        email: "jean.mmegne@gmail.com",
      },
    ],
  },
  {
    code: "RMPP (MEJ)",
    name: "IVORY COAST",
    offices: [
      {
        code: "RMPP (MEJ)",
        name: "P. Oladigui Olawy Michael ",
        role: "National Coordinator",
        email: "reseaumondialdeprieredupapeci@gmail.com",
      },
      {
        code: "MEJ",
        name: "Sopie Odette Yapi",
        role: "Responsable Nationale MEJ",
        email: "ritasophie82yapi@gmail.com",
      },
    ],
  },
  {
    code: "PWPN",
    name: "KENYA",
    offices: [
      {
        code: "EYM",
        name: "Fr. Jonas Kiplimo",
        role: "National Coordinator",
        email: "omilpik@gmail.com",
      },
    ],
  },
  {
    code: "RMPP (MEJ)",
    name: "MADAGASCAR",
    offices: [
      {
        code: "RMPP (MEJ)",
        name: "P. Odon Andrianarisoa SJ",
        role: "National Director",
        email: "aomonie.nasionaly@afafi-fet.org",
      },
      {
        code: "RMPP (MEJ)",
        name: "P. Christophe Zakavelo SJ",
        role: "Assistant",
        email: "zj_christophe@yahoo.fr",
      },
    ],
  },
  {
    code: "EYM",
    name: "MAURITIUS ISLANDS",
    offices: [
      {
        code: "EYM",
        name: "Johanne Quevauvilliers",
        role: "National Coordinator",
        email: "johannecunat@yahoo.fr",
      },
    ],
  },
  {
    code: "PWPN (EYM)",
    name: "MOZAMBIQUE",
    offices: [
      {
        code: "PWPN (EYM)",
        name: "P. Afonso Mucane SJ",
        role: "National Coordinator",
        email: "amalapo@yahoo.com",
      },
    ],
  },
  {
    code: "PWPN (EYM)",
    name: "NIGERIA",
    offices: [
      {
        code: "PWPN (EYM)",
        name: "Fr Hiifan Ikyondo SJ",
        role: "National Director",
        email: "hiifan13@yahoo.com",
      },
    ],
  },
  {
    code: "MEJ",
    name: "REPUBLICA DE GUINEA ECUATORIAL",
    offices: [
      {
        code: "MEJ",
        name: "Patricia Moguel Magaña RJM",
        role: "Contact",
        email: "patriciamoguelrjm@yahoo.com.mx",
      },
    ],
  },
  {
    code: "RMPP(MEJ)",
    name: "REP. DEM. CONGO",
    offices: [
      {
        code: "RMPP(MEJ)",
        name: "P. Gilbert MBAMBI KIKA-SISKA SJ",
        role: "National Director",
        email: "mbambikika@yahoo.fr",
      },
    ],
  },
  {
    code: "PWPN",
    name: "REP. SOUTH AFRICA",
    offices: [
      {
        code: "PWPN",
        name: "Fr. Chris Chatteris SJ",
        role: "National Coordinator",
        email: "chrischatterissj@gmail.com",
      },
    ],
  },
  {
    code: "RMPP (MEJ)",
    name: "RWANDA",
    offices: [
      {
        code: "RMPP (MEJ)",
        name: "Martin Murekezi",
        role: "National Coordinator",
        email: "rmpprwanda@gmail.com",
      },
      {
        code: "RMPP (MEJ)",
        name: "Abbé Gilbert KWITONDA",
        role: "National Chaplain",
        email: "gilbertkwitonda2018@gmail.com",
      },
    ],
  },
  {
    code: "PWPN (EYM)",
    name: "TANZANIA",
    offices: [
      {
        code: "PWPN (EYM)",
        name: "Fr. Josephat Leander Kabutta SJ",
        role: "Regional Coordinator",
        email: "director.popesprayer.aor@gmail.com",
      },
    ],
  },
  {
    code: "RMPP",
    name: "TCHAD",
    offices: [
      {
        code: "",
        name: "",
        role: "",
        email: "",
      },
    ],
  },
  {
    code: "RMPP (MEJ)",
    name: "TOGO",
    offices: [
      {
        code: "RMPP (MEJ)",
        name: "Nestor Gounteni Foeyeme",
        role: "National Coordinator",
        email: "foeyeme@yahoo.fr",
      },
    ],
  },
  {
    code: "PWPN",
    name: "UGANDA",
    offices: [
      {
        code: "PWPN",
        name: "Bro Sserugga Noa Mawaggali",
        role: "National Coordinator",
        email: "nmsseru@gmail.com",
      },
    ],
  },
  {
    code: "PWPN",
    name: "ZAMBIA-MALAWI",
    offices: [
      {
        code: "PWPN",
        name: "Fr. Charles Searson SJ",
        role: "Regional Coordinator",
        email: "searson@jesuits.net",
      },
    ],
  },
  {
    code: "PWPN",
    name: "ZIMBABWE",
    offices: [
      {
        code: "PWPN",
        name: "Fr. Konrad Landsberg SJ",
        role: "National Coordinator",
        email: "klandsberg@mango.zw",
      },
    ],
  },
  // Europa
  {
    code: "PWPN",
    name: "AUSTRIA",
    offices: [
      {
        code: "PWPN",
        name: "Fr. Michael Zacherl SJ",
        role: "National Director",
        email: "michael.zacherl@jesuiten.org",
      },
    ],
  },
  {
    code: "EYM",
    name: "BELARUS",
    offices: [
      {
        code: "",
        name: "",
        role: "",
        email: "",
      },
    ],
  },
  {
    code: "PWPN",
    name: "NETHERLANDS",
    offices: [
      {
        code: "PWPN",
        name: "Fr. Nikolaas Sintobin SJ",
        role: "Regional Coordinator",
        email: "nikolaas.sintobin@jesuits.net",
      },
    ],
  },
  {
    code: "RMPP",
    name: "BELGIUM (FR)",
    offices: [
      {
        code: "RMPP",
        name: "",
        role: "National Coordinator",
        email: "",
      },
      {
        code: "MEJ",
        name: "Benita Mutoni",
        role: "MEJ Liège",
        email: "benita.mutoni@sdjliege.be ",
      },
      {
        code: "MEJ",
        name: "P. Thomas Sabbadini",
        role: "Aumônier MEJ Liège",
        email: "thomas.sabbadini@gmail.com",
      },
    ],
  },
  {
    code: "PWPN",
    name: "CROATIA",
    offices: [
      {
        code: "PWPN",
        name: "Fr. Zvonko Vlah SJ",
        role: "National Director",
        email: "zvlah@globalnet.hr",
      },
      {
        code: "PWPN",
        name: "Fr. Pavel Ambros SJ",
        role: "National Director",
        email: "pavel.ambros@upol.cz",
      },
    ],
  },
  {
    code: "PWPN",
    name: "CZECH REPUBLIC",
    offices: [
      {
        code: "PWPN",
        name: "Fr. Pavel Ambros SJ",
        role: "National Director",
        email: "pavel.ambros@upol.cz",
      },
    ],
  },
  {
    code: "PWPN",
    name: "DENMARK",
    offices: [
      {
        code: "PWPN",
        name: "Fr. Herbert Krawczyk SJ",
        role: "National Coordinator",
        email: "herbert@katolsk-aarhus.dk",
      },
    ],
  },
  {
    code: "RMPP",
    name: "FRANCE",
    offices: [
      {
        code: "RMPP",
        name: "P. Daniel Régent SJ",
        role: "National Director",
        email: "daniel.regent@jesuites.com",
      },
      {
        code: "MEJ",
        name: "Sophie Lassagne",
        role: "National Director",
        email: "direction@mej.fr",
      },
      {
        code: "MEJ",
        name: "Jonathan Dolidon",
        role: "National Chaplain",
        email: "jdolidon@jesuites.com",
      },
    ],
  },
  {
    code: "PWPN",
    name: "GERMANY",
    offices: [
      {
        code: "PWPN",
        name: "Fr. Dag Heinrichowski SJ",
        role: "National Coordinator",
        email: "dag.heinrichowski@jesuiten.org",
      },
    ],
  },
  {
    code: "PWPN",
    name: "GREECE",
    offices: [
      {
        code: "PWPN",
        name: "Fr. Pierre Chongk Tzoun-Chan SJ",
        role: "National Coordinator",
        email: "",
      },
    ],
  },
  {
    code: "PWPN",
    name: "HUNGARY",
    offices: [
      {
        code: "PWPN",
        name: "Fr. György Hiba SJ",
        role: "National Coordinator",
        email: "hiba.gyorgy@jezsuita.hu",
      },
    ],
  },
  {
    code: "PWPN",
    name: "IRELAND",
    offices: [
      {
        code: "PWPN",
        name: "Fr. Donal Neary SJ",
        role: "National Director",
        email: "dneary@messenger.ie",
      },
    ],
  },
  {
    code: "PWPN",
    name: "ITALIA",
    offices: [
      {
        code: "PWPN",
        name: "Fr. Renato Colizzi SJ",
        role: "National Director",
        email: "colizzi.r@gesuiti.it",
      },
    ],
  },
  {
    code: "PWPN",
    name: "LITUANIA",
    offices: [
      {
        code: "PWPN",
        name: "Fr. Stasys Kazėnas",
        role: "National Coordinator",
        email: "kazenas.sj@gmail.com",
      },
    ],
  },
  {
    code: "RMPP",
    name: "LUXEMBOURG",
    offices: [
      {
        code: "RMPP",
        name: "Michele and Isabella Camposeo",
        role: "National Coordinators",
        email: "prieredupape@cathol.lu",
      },
    ],
  },
  {
    code: "PWPN (EYM)",
    name: "MALTA",
    offices: [
      {
        code: "PWPN (EYM)",
        name: "Fr. Raymond Pace SJ",
        role: "National Coordinator",
        email: "raypacesj@gmail.com",
      },
    ],
  },
  {
    code: "PWPN",
    name: "POLAND",
    offices: [
      {
        code: "PWPN",
        name: "P. Robert Więcek SJ",
        role: "National Director",
        email: "am@ampolska.co",
      },
      {
        code: "EYM",
        name: "Fr. Grzegorz Wisniewski",
        role: "National Director",
        email: "grzegorz.wisniewski@archidiecezjakatowicka.pl",
      },
    ],
  },
  {
    code: "PWPN",
    name: "PORTUGAL",
    offices: [
      {
        code: "PWPN",
        name: "Fr. Antonio de Magalhaes Sant’Ana SJ",
        role: "National Director",
        email: "antoniosantana_sj@jesuits.net",
      },
    ],
  },
  {
    code: "PWPN",
    name: "RUMANIA",
    offices: [
      {
        code: "PWPN",
        name: "Fr. Olivo Bosa SJ",
        role: "National Coordinator",
        email: "bosa.o@iezuiti.ro",
      },
    ],
  },
  {
    code: "PWPN",
    name: "SLOVAKIA",
    offices: [
      {
        code: "PWPN",
        name: "Fr. Ján Benkovský SJ",
        role: "National Coordinator",
        email: "jbenkovsky@gmail.com",
      },
    ],
  },
  {
    code: "PWPN",
    name: "SLOVENIA",
    offices: [
      {
        code: "PWPN",
        name: "Fr. Franc Kejžar SJ",
        role: "National Coordinator",
        email: "franc.kejzar@rkc.si",
      },
    ],
  },
  {
    code: "RMOP (MEJ)",
    name: "SPAIN",
    offices: [
      {
        code: "PWPN",
        name: "David Fornieles",
        role: "National Director",
        email: "davidfornieles@hotmail.com",
      },
    ],
  },
  {
    code: "PWPN",
    name: "SWEDEN",
    offices: [
      {
        code: "PWPN",
        name: "Fr. Rainer Carls SJ",
        role: "National Coordinator",
        email: "pater.carls@gmail.com",
      },
    ],
  },
  {
    code: "RMPP",
    name: "SWITZERLAND (FR)",
    offices: [
      {
        code: "RMPP",
        name: "Dominique Schenker",
        role: "National Responsable",
        email: "dominique.schenker@hefr.ch",
      },
    ],
  },
  {
    code: "PWPN",
    name: "UNITED KINGDOM",
    offices: [
      {
        code: "PWPN",
        name: "Fr. Eddy Bermingham SJ ",
        role: "National Coordinator",
        email: "eberminghamsj@jesuit.org.uk",
      },
    ],
  },
  {
    code: "RMPP",
    name: "WALLONY - BRUSSELS",
    offices: [
      {
        code: "RMPP",
        name: "Pierre and Lydie Siasia ",
        role: "Regional Coordinators",
        email: "prieredupape@catho.be",
      },
    ],
  },
  // Asia - Oceania
  {
    code: "PWPN",
    name: "AUSTRALIA",
    offices: [
      {
        code: "PWPN",
        name: "John and Anne Gray",
        role: "National Coordinator",
        email: "john.a.gray88@gmail.com",
      },
    ],
  },
  {
    code: "PWPN",
    name: "BANGLADESH",
    offices: [
      {
        code: "PWPN",
        name: "Fr. Milton Rozario",
        role: "National Director",
        email: "frmrozario@yahoo.com",
      },
    ],
  },
  {
    code: "MEJ",
    name: "EAST TIMOR",
    offices: [
      {
        code: "MEJ",
        name: "",
        role: "",
        email: "",
      },
    ],
  },
  {
    code: "PWPN (EYM)",
    name: "GUAM (MICRONESIA)",
    offices: [
      {
        code: "PWPN (EYM)",
        name: "Fr. Thomas G. Benz SJ",
        role: "Person in charge",
        email: "tbenz@jesuits.org",
      },
      {
        code: "PWPN (EYM)",
        name: "Fr. Antonius Reynolds Balubun, sdv",
        role: "National Coordinator",
        email: "baauhk@yahoo.com",
      },
    ],
  },
  {
    code: "PWPN (EYM)",
    name: "HONG KONG",
    offices: [
      {
        code: "PWPN (EYM)",
        name: "Fr. Antonius Reynolds Balubun, sdv",
        role: "National Coordinator",
        email: "baauhk@yahoo.com",
      },
    ],
  },
  {
    code: "PWPN (EYM)",
    name: "INDIA",
    offices: [
      {
        code: "PWPN (EYM)",
        name: "Fr Jagdish Parmar SJ",
        role: "National Director",
        email: "pwpn.eym@gmail.com",
      },
    ],
  },
  {
    code: "PWPN",
    name: "INDONESIA",
    offices: [
      {
        code: "PWPN",
        name: "Fr. Gabriel Possenti SJ",
        role: "National Director",
        email: "National Director",
      },
      {
        code: "EYM",
        name: "Fr. Yohanes Nugroho SJ",
        role: "National Coordinator",
        email: "johnugsj@jesuits.net",
      },
    ],
  },
  {
    code: "PWPN",
    name: "JAPAN",
    offices: [
      {
        code: "PWPN",
        name: "Fr. Toshihiro Yanagida SJ",
        role: "National Director",
        email: "yanagida@jesuits.or.jp",
      },
    ],
  },
  {
    code: "PWPN",
    name: "MALAYSIA",
    offices: [
      {
        code: "PWPN",
        name: "Fr. Lawrence Andrew SJ",
        role: "National coordinator",
        email: "laandresj@gmail.com",
      },
    ],
  },
  {
    code: "EYM",
    name: "NOUVELLE CALEDONIA",
    offices: [
      {
        code: "EYM",
        name: "",
        role: "",
        email: "",
      },
    ],
  },
  {
    code: "PWPN (EYM)",
    name: "PHILIPPINES",
    offices: [
      {
        code: "PWPN (EYM)",
        name: "Fr. Victor De Jesus SJ",
        role: "National Director",
        email: " vicdejesus@gmail.com",
      },
    ],
  },

  {
    code: "PWPN",
    name: "SOUTH KOREA",
    offices: [
      {
        code: "PWPN",
        name: "Fr. Joseph Woo-Bae Sohn SJ",
        role: "National Coordinator",
        email: "woobae@gmail.com",
      },
    ],
  },
  {
    code: "PWPN (EYM)",
    name: "SRI LANKA",
    offices: [
      {
        code: "PWPN (EYM)",
        name: "Fr. Sanjeeva Fernando SJ",
        role: "National Director",
        email: "sanjsj@gmail.com",
      },
    ],
  },
  {
    code: "PWPN (EYM)",
    name: "TAIWAN",
    offices: [
      {
        code: "PWPN (EYM)",
        name: "Fr. Mathew Hsu Shen-Yi SJ",
        role: "Regional Director",
        email: "vsenyisj@yahoo.com",
      },
    ],
  },
  {
    code: "PWPN",
    name: "THAILAND",
    offices: [
      {
        code: "PWPN",
        name: "Fr. Paul Kriangyot SJ",
        role: "Person in charge",
        email: "paulkriangyot@jesuits.net",
      },
    ],
  },
  {
    code: "PWPN",
    name: "VIETNAM",
    offices: [
      {
        code: "PWPN",
        name: "Fr. Peter Tran Vinh Danh SJ",
        role: "National Coordinator",
        email: "ranvdanh@gmail.com",
      },
      {
        code: "EYM",
        name: "Joseph Pham Duc Tuan's",
        role: "General Chaplain",
        email: "phamductuan@yahoo.com",
      },
    ],
  },
  // Medio Oriente
  {
    code: "PWPN",
    name: "PROCHE ORIENT",
    offices: [
      {
        code: "PWPN",
        name: "Fr.Zaki Sader SJ",
        role: "Regional Director",
        email: "zakisj@jespro.org",
      },
    ],
  },
  {
    code: "EYM",
    name: "EGYPT",
    offices: [
      {
        code: "EYM",
        name: "Fr. Magdi-Nazmi SJ",
        role: "National Chaplain",
        email: "magdinazmi@yahoo.com",
      },
    ],
  },
  {
    code: "EYM",
    name: "LEBANON",
    offices: [
      {
        code: "EYM",
        name: "Céline Khoury Samia",
        role: "National Head Chief",
        email: "mejliban@mejliban.com",
      },
      {
        code: "EYM",
        name: "Fr. Jad Jabbour SJ",
        role: "National Chaplain",
        email: "jad.jabbour@jesuits.net",
      },
    ],
  },
  // America
  {
    code: "RMOP (MEJ)",
    name: "ARGENTINA-URUGUAY",
    offices: [
      {
        code: "RMOP (MEJ)",
        name: "Bettina Raed",
        role: "Regional Director",
        email: "bettina.raed@rmoparu.org",
      },
    ],
  },
  {
    code: "RMOP (MEJ)",
    name: "BOLIVIA",
    offices: [
      {
        code: "PWPN",
        name: "Gonzalo and Roxana Cárdenas",
        role: "National Coordinators",
        email: "goncarde@gmail.com - pereira.roxana@gmail.com",
      },
    ],
  },
  {
    code: "RMOP (MEJ)",
    name: "BRASIL",
    offices: [
      {
        code: "RMOP (MEJ)",
        name: "P. Eliomar Ribeiro SJ",
        role: "National Director",
        email: "eliomarsj@hotmail.com",
      },
    ],
  },
  {
    code: "RMOP (MEJ)",
    name: "CHILE",
    offices: [
      {
        code: "RMOP (MEJ)",
        name: "Maritza Perez Guzman",
        role: "National Director",
        email: "directornacional@aomej.cl",
      },
    ],
  },
  {
    code: "RMOP (MEJ)",
    name: "COLOMBIA",
    offices: [
      {
        code: "RMOP (MEJ)",
        name: "P. Carlos Franco Revello SJ",
        role: "National Director",
        email: "carlosrevelosj@gmail.com",
      },
    ],
  },
  {
    code: "RMOP (MEJ)",
    name: "COSTA RICA",
    offices: [
      {
        code: "RMOP (MEJ)",
        name: "P. Elliot Rojas",
        role: "National Coordinator",
        email: "elliott124@gmail.com",
      },
    ],
  },
  {
    code: "RMOP",
    name: "CUBA",
    offices: [
      {
        code: "RMOP",
        name: "P. Eduardo Llorens SJ",
        role: "National Coordinator",
        email: "ellorens@yahoo.com",
      },
    ],
  },
  {
    code: "RMOP (MEJ)",
    name: "ECUADOR",
    offices: [
      {
        code: "RMOP (MEJ)",
        name: "P. Juan Cavanna SJ",
        role: "National Coordinator",
        email: "jcavannasi@gmail.com",
      },
    ],
  },
  {
    code: "RMOP",
    name: "EL SALVADOR",
    offices: [
      {
        code: "RMOP",
        name: "Nery José Quevedo Madrid",
        role: "National Coordinator",
        email: "nery.quevedo17@gmail.com",
      },
      {
        code: "MEJ",
        name: "Gerardo Ernesto Del Valle Campos",
        role: "National Coordinator",
        email: "gdelvalle92@gmail.com",
      },
    ],
  },
  {
    code: "RMOP",
    name: "GUATEMALA",
    offices: [
      {
        code: "RMOP",
        name: "Diego Martínez",
        role: "National Coordinator",
        email: "rmopgtca@gmail.com",
      },
    ],
  },
  {
    code: "MEJ",
    name: "HAITI",
    offices: [
      {
        code: "MEJ",
        name: "Luce Mirla Paul Dorizan",
        role: "National Coordinator",
        email: "lucemirlap13@gmail.com",
      },
      {
        code: "MEJ",
        name: "P. Eric Jasmin",
        role: "National Chaplain",
        email: "ericjasmin2003@yahoo.fr",
      },
    ],
  },
  {
    code: "",
    name: "MEXICO",
    offices: [
      {
        code: "PWPN",
        name: "Fr. Sergio Manuel Guzmán García SJ",
        role: "National Coordinator",
        email: "checoguz@hotmail.com",
      },
      {
        code: "EYM",
        name: "Celina Segovia Sarlat, rjm",
        role: "National Coordinator",
        email: "celinarjm@hotmail.com",
      },
    ],
  },
  {
    code: "",
    name: "PARAGUAY",
    offices: [
      {
        code: "RMOP",
        name: "Soledad Talavera",
        role: "National Coordinator",
        email: "soletalavera@gmail.com",
      },
      {
        code: "MEJ",
        name: "Lidia Carlota Carbajal Diaz ODN",
        role: "National Director",
        email: "lidia.carbajal@cdm.edu.co",
      },
    ],
  },
  {
    code: "RMOP (MEJ)",
    name: "PERU",
    offices: [
      {
        code: "RMOP (MEJ)",
        name: "P. José Luis Gordillo SJ",
        role: "National Coordinator",
        email: "josegordillosj@gmail.com",
      },
    ],
  },
  {
    code: "PWPN",
    name: "REP. DOMINICANA",
    offices: [
      {
        code: "PWPN",
        name: "Fr. Juan de Jesús Jiménez Portes SJ",
        role: "National Coordinator",
        email: "jjjsj@hotmail.com",
      },
    ],
  },
  {
    code: "PWPN",
    name: "TRINIDAD Y TOBAGO",
    offices: [
      {
        code: "PWPN",
        name: "",
        role: "",
        email: "",
      },
    ],
  },
  {
    code: "RMOP (MEJ)",
    name: "URUGUAY",
    offices: [
      {
        code: "PWPN",
        name: "Mónica Fernández",
        role: "National Coordinator",
        email: "monica.fernandez@rmoparu.org",
      },
    ],
  },
  {
    code: "RMOP",
    name: "VENEZUELA",
    offices: [
      {
        code: "RMOP",
        name: "P. Alvaro Lacasta SJ",
        role: "National Director",
        email: "aporlacasta@hotmail.com",
      },
    ],
  },
  {
    code: "PWPN",
    name: "U.S.A.",
    offices: [
      {
        code: "PWPN",
        name: "Fr. Joseph Laramie SJ",
        role: "National Director",
        email: "frlaramiesj@popesprayerusa.net",
      },
      {
        code: "EYM (TNTT)",
        name: "P. Binh Nguyen svd",
        role: "National Director",
        email: "binhsvd@gmail.com",
      },
    ],
  },
  {
    code: "PWPN",
    name: "CANADA",
    offices: [
      {
        code: "PWPN",
        name: "Fr. Edmund Lo SJ",
        role: "National Coordinator",
        email: "elo@jesuits.org",
      },
      {
        code: "EYM",
        name: "Sr. Lourdes Varguez RJM",
        role: "National Coordinator",
        email: "eymcanadamej@gmail.com",
      },
    ],
  },
];
