import React from "react";
import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";
import classnames from 'classnames';
import ListCard from "components/Cards/ListCard";
import SinglePrayer from "components/SinglePrayer";
import PopeCard from "components/Cards/PopeCard";
import Grid from "@material-ui/core/Grid";
import Carrusel from "components/Carrusel";
import Button from "components/Button";
import Icon from "components/Icon";
import VideoPlayer from "components/VideoPlayer";
import BlogCard from "components/Cards/BlogCard";
import ArrowLeft from "assets/icons/Carrusel_Arrow_left.svg";
import ArrowRight from "assets/icons/Carrusel_Arrow_right.svg";
import ArrowRightWhite from "assets/icons/arrow_right_white.svg";

const HomeView = (props) => {
  const { t } = useTranslation();
  const {
    intention,
    dateIntention,
    positionIntention,
    initialDate,
    prayers,
    prayingTips,
    nextIntention,
    backIntention,
    backgroundImage,
    handleCardIntentionVideo,
    handleCardIntentionPrayer,
    handleCardIntentionAttitudes,
    handleMultimedia,
    handleClickPrayCard,
    handleCloseSinglePrayer,
    isModalSinglePrayer,
    singlePrayer,
    isPlay,
    isSmall,
    isXs,
    blogs,
    handleReadArticle,
    schoolPrayers,
    navigateSchoolPrayer,
    navigateBlog,
    handleClickSchoolPrayer,
    isPopeVideo = false,
    handleClosePopeVideo,
    handleFavorite,
    handleDislike,
  } = props;

  const classes = useStyles({
    mediaQuerySm: isSmall,
    mediaQueryXs: isXs,
    backgroundImage: backgroundImage,
    hasPopeCard: dateIntention?.dayNumber < 8,
  });

  const carrusel = (children) => {
    return (
      <Carrusel
        id="carrusel_content"
        indicators={false}
        autoPlay={false}
        className = {classes.carrusel}
        navButtonsWrapperProps={{
          style: {
            display: "flex",
          },
        }}
        NavButton={({ next }) => {
          return (
            <Button
              id="btn_navigation"
              data-testid="btn_navigation"
              onClick={next ? nextIntention : backIntention}
              icon={
                next ? (
                  <Icon
                    data-testid="ico_next"
                    id="ico_next"
                    icon={ArrowRight}
                    alt="arrow right"
                  />
                ) : (
                  <Icon
                    data-testid="ico_back"
                    id="ico_back"
                    icon={ArrowLeft}
                    alt="arrow left"
                  />
                )
              }
              type="transparent"
              className={classes.buttonCarrusel}
            />
          );
        }}
        index={positionIntention}
        cycleNavigation={false}
      >
        {children}
      </Carrusel>
    );
  };
  const popeCard = () => {
    return (
      <>
        {dateIntention?.dayNumber < 8 && (
          <div className={classes.sectionCardIntention}>
            <PopeCard
              description={intention?.description}
              month={dateIntention?.month.toLowerCase()}
              video={intention?.video}
              handleVideo={() => handleCardIntentionVideo()}
              handlePrayer={() => handleCardIntentionPrayer()}
              handleAttitudes={() => handleCardIntentionAttitudes()}
              isSmall={isSmall}
              id="crd_pope_container_0"
              idTitle="txt_content_title_0"
              idDescription="txt_content_description_0"
              idAvatar="ico_pope_0"
            />
          </div>
        )}
      </>
    );
  };
  const dowload = (css) => {
    return (
      <div id="text_content_dowload" className={css ? css : null}>
        <p data-testid="txt_agenda_praying">
          {t(
            "pages.home.Organize your prayer schedule on the Click To Pray App"
          )}
        </p>
        <div>
          <Button
            data-testid="btn_appStore"
            type="primary"
            text="components.footer.AppStore"
            css={classes.buttonAppstore}
            onClick={() =>
              window.open(
                "https://apps.apple.com/ar/app/click-to-pray/id934935942"
              )
            }
          />
          <Button
            onClick={() =>
              window.open(
                "https://play.google.com/store/apps/details?id=com.lamachi.clicktopray"
              )
            }
            data-testid="btn_googlePlay"
            type="primary"
            text="components.footer.Google Play"
          />
        </div>
      </div>
    );
  };
  const intentionDate = () => {
    return (
      <div id="content_date">
        <p data-testid="content_date_days">{dateIntention?.day}</p>
        <p data-testid="content_date_dayNumber">{dateIntention?.dayNumber}</p>
        <p data-testid="content_date_month">{dateIntention?.month}</p>
      </div>
    );
  };
  const intentionMonth = () => {
    return (
      <p data-testid="content_date_txt">
        {t("pages.home.Pope Francis’ Intentions for")}
      </p>
    );
  };
  const prayerTipsSection = () => {
  return (
    <div className={classes.ContentPrayingTip}>
    {prayingTips?.length > 0 ? (
      <div className={classes.WrapperPrayingTip}>
        <div className={classes.textContent}>
          <Grid container justify="center" alignItems="center">
            <Grid
              id="txt_content_title"
              item
              xs={12}
              sm={12}
              md={3}
              lg={3}
              xl={3}
            >
              <h1 data-testid="txt_home_praying">
                {t("pages.home.Praying Tip")}
              </h1>
            </Grid>
            <Grid
              id="txt_content_prayingTips"
              item
              xs={12}
              sm={12}
              md={8}
              lg={8}
              xl={8}
            >
              <p data-testid="txt_home_praying_tip">{prayingTips}</p>
            </Grid>
          </Grid>
        </div>
      </div>
    ) :null}
    </div>
    );
  }
  return (
    <div data-testid={`home_view`} className={classes.homeContent}>
      <section className={`${classes.sectionCard}`}>
        {intention.title ? (
          <div className={classes.wrapperIntention}>
            <div className={classes.contentIntention}>
              {isSmall ? (
                <>
                  <Grid style={props} container>
                    <Grid item xs={12} className={classes.descriptionIntention}>
                      <div>
                        <p data-testid="home_intention">{intention?.title}</p>
                        <hr />
                        {intentionMonth()}
                      </div>
                    </Grid>
                  </Grid>

                  {initialDate.length > 0 &&
                    carrusel(
                      initialDate.map((date, index) => (
                        <Grid key={index} container>
                          <Grid className={classes.dateIntention} item xs={12}>
                            {intentionDate()}
                          </Grid>
                        </Grid>
                      ))
                    )}
                </>
              ) : (
                <>
                  {initialDate?.length > 0 &&
                    carrusel(
                      initialDate.map((date, index) => (
                        <Grid key={index} container>
                          <Grid className={classes.dateIntention} item xs={4}>
                            {intentionDate()}
                          </Grid>
                          <Grid
                            item
                            xs={7}
                            className={classes.descriptionIntention}
                          >
                            <div id="content_title_intention">
                              <p data-testid="home_intention">
                                {intention.title}
                              </p>
                              {intentionMonth()}
                            </div>
                          </Grid>
                        </Grid>
                      ))
                    )}
                </>
              )}
            </div>
            <VideoPlayer
              open={isPopeVideo}
              handleClose={handleClosePopeVideo}
              src={intention?.video}
              isSmall={isSmall}
            />
            {!isSmall && popeCard()}
            {prayers?.length > 0 && (
              <ListCard
                handleClickPrayCard={handleClickPrayCard}
                list={prayers}
                isSmall={isSmall}
              />
            )}
            {isSmall && popeCard()}
            {!isSmall && dowload(classes.dowload)}
          </div>
        ) : null}

        {isSmall && dowload(classes.dowload)}


        <SinglePrayer
          description={singlePrayer?.description}
          title={singlePrayer?.title}
          offering={singlePrayer?.offering}
          icon={singlePrayer?.icon}
          textHover={singlePrayer?.textHover}
          audio={singlePrayer?.audio}
          open={isModalSinglePrayer}
          handleClose={handleCloseSinglePrayer}
          handleMultimedia={handleMultimedia}
          isPlay={isPlay}
          isSmall={isSmall}
          isModal
          handleFavorite={() => {
            handleFavorite(singlePrayer?.id, singlePrayer?.typeForAmen);
          }}
          handleDislike={() => {
            handleDislike(singlePrayer?.id, singlePrayer?.typeForAmen);
          }}
          owner={singlePrayer?.id}
          ownerType={singlePrayer?.typeForAmen}
          ownerGroup={singlePrayer?.groupData}
        />
      </section>
      {prayerTipsSection()}
      {intention.title ? (
      <section className={classes.sectionIntention}>
        <Grid container>
          <Grid item xs={12}>
            <div id="txt_content_intention" className={classes.intention}>
              <h1 data-testid="txt_home_title">{intention?.title}</h1>
              {isSmall && <hr />}
              {intentionMonth()}
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.wrapperPoperCard}>
              {
                <PopeCard
                  description={intention?.description}
                  month={dateIntention?.month.toLowerCase()}
                  video={intention?.video}
                  handleVideo={() => handleCardIntentionVideo()}
                  handlePrayer={() => handleCardIntentionPrayer()}
                  handleAttitudes={() => handleCardIntentionAttitudes()}
                  isSmall={isSmall}
                  id="crd_pope_container_1"
                  idTitle="txt_content_title_1"
                  idDescription="txt_content_description_1"
                  idAvatar="ico_pope_1"
                />
              }
            </div>
          </Grid>
        </Grid>
      </section>)
      :null}

      {!!blogs?.length>0 ? (
        <section className={classes.sectionBlog}>
          <div id="txt_content_title_blog" className={classes.wrapperTitleBlog}>
            <h1 data-testid="txt_content_title_blog">
              {t(`pages.home.Blog To go deeper`)}
            </h1>
            {isSmall && <hr />}
            <Button
              onClick={navigateBlog}
              css={classes.buttonSchoolPrayer}
              iconRight={
                <Icon
                  id="ico_Blog"
                  icon={ArrowRightWhite}
                  alt="arrow right white"
                />
              }
              text="pages.home.More articles"
              type="secondary"
              data-testid="txt_blog"
            />
          </div>
          <div className={classes.wrapperBlogs}>
            <Grid className={classes.gridContainerBlog} container>
              {blogs?.map(
                (
                  { title, blockquote, multimedia, id, publish_date },
                  index
                ) => {
                  return (
                    <Grid
                      key={index}
                      xs={12}
                      sm={12}
                      md={10}
                      lg={10}
                      xl={10}
                      item
                    >
                      <BlogCard
                        id={id}
                        handleReadArticle={handleReadArticle}
                        css={classnames(classes.cardBlog, classes.contentHomeCardBlog)}
                        description={blockquote}
                        title={title}
                        img={
                          (multimedia?.find(m=> m.type.code === 'IMG_POS_CROP'))?.url || multimedia[0]?.url
                        }
                        isReverse={index !== 0 ? true : false}
                        isMd={isSmall}
                        lineDescription={3}
                        date={publish_date}
                        isHome={true}
                      />
                    </Grid>
                  );
                }
              )}
            </Grid>
          </div>
        </section>
      ):null}
      {schoolPrayers?.length>0 ?(
        <section className={classes.sectionSchoolPrayer}>
        <div className={classes.wrapperTitleSchoolPrayer}>
          <h1 data-testid="txt_school_prayer">
            {t(`pages.home.School of Prayer`)}
          </h1>
          {isSmall && <hr />}
          <Button
            onClick={navigateSchoolPrayer}
            css={classes.buttonSchoolPrayer}
            iconRight={
              <Icon
                id="ico_SchoolPrayer"
                icon={ArrowRightWhite}
                alt="arrow right white"
              />
            }
            text="pages.home.Pray more"
            type="secondary"
            data-testid="txt_pray_more"
          />
        </div>
        <div className={classes.wrapperSchoolPrayer}>
          <ListCard
            cssCard={classes.cardSchoolPrayer}
            handleClickPrayCard={handleClickSchoolPrayer}
            doubleCard
            list={schoolPrayers}
            isSmall={isSmall}
          />
        </div>
        <div className={classes.contentDowload}>{dowload()}</div>
      </section>) : null}
    </div>
  );
};

HomeView.propTypes = {};

export default HomeView;
