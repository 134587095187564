export default {
  light: {
    primary: {
      color: {
        main: "#444C67",
        text: "#FFFFFF",
      },
      action: {
        hover: "#5D688E",
        disabled: "#E7ECF0",
      },
    },
    secondary: {
      color: {
        main: "#E7C26D",
        text: "#FFFFFF",
      },
      action: {
        hover: "#CAB075",
        disabled: "#F8EDD3",
      },
    },
    tertiary: {
      color: {
        main: "transparent",
        text: "#CAB075",
      },
      action: {
        hover: "#F5F1E4",
        disabled: "#F8EDD3",
      },
    },
    cuaternary: {
      color: {
        main: "transparent",
        text: "#444C67",
      },
      action: {
        hover: "#E7ECF0",
        disabled: "#F8EDD3",
      },
    },
    transparent: {
      color: {
        main: "transparent",
      },
      action: {
        hover: "transparent",
        disabled: "transparent",
      },
    },
    facebook: {
      color: {
        main: "#375AD3",
      },
      action: {
        hover: "#233A87",
      },
    },
    gmail: {
      color: {
        main: "#C92121",
      },
      action: {
        hover: "#7D1414",
      },
    },
  },
};
